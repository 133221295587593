export default {
  state: {
    dataCategory: {
      region: [{ id: "", name: "" }],
      category: [{ id: "", name: "" }],
      object_type: [{ id: "", name: "" }],
      morpheme: [{ id: "", name: "" }],
    },
    actionsCategory: {},
  },

  actions: {
    SET_CATEGORY(ctx, payload) {
      this.payload = payload;
      axios({
        method: payload.method,
        url: payload.url,
        data: payload.data,
      })
        .then((response) => {
          ctx.commit(this.payload.commitName, {
            responseData: response.data,
            id: this.payload.id,
            nameCategory: this.payload.nameCategory,
          });
          this.payload.notify({
            group: "edit",
            text: "Изменения сохранены",
            duration: 3000,
          });
          this.payload.modal.hide(this.payload.modalName);
        })
        .catch((e) => {
          if (!e.response || e.response.status === 500) {
            this.payload.notify({
              group: "edit-error",
              text: "Ошибка сервера, попробуйте ещё раз",
              duration: 3000,
            });
          }

          console.log(e)
          const arrayError = e.response.data.errors;
          // ctx.commit("addErrors", arrayError);
          this.payload.notify({
            group: "warning",
            text: e.response.data.errors,
            duration: 3000000,
          });
        });
    },
  },

  mutations: {
    async setCategories(state, data) {
      state.dataCategory = data;
    },
    SET_ACTIONS_CATEGORY(state, actions) {
      state.actionsCategory = actions;
    },
    delCategory(state, { responseData, id, nameCategory }) {
      if (responseData === 1) {
        state.dataCategory[nameCategory].splice(
          state.dataCategory[nameCategory].findIndex((item) => item.id === id),
          1
        );
      }
    },
    updateCategory(state, { responseData, id }) {
      const key = Object.keys(responseData);
 
      state.dataCategory[key[0]].splice(
        state.dataCategory[key[0]].findIndex((item) => item.id === id),
        1,
        responseData[key[0]]
      );
    },
    SET_NEW_CATEGORY(state, { responseData }) {
      console.log(responseData)
      const key = Object.keys(responseData);
      state.dataCategory[key[0]].push(responseData[key[0]]);
    },
  },

  getters: {
    getDataCategory(state) {
      return state.dataCategory;
    },
    getActionsCategory(state) {
      return state.actionsCategory;
    },
  },
};
