import store from 'vuex'
export default {
  state: {
    data: [],
    errors: {},
    orderData: [],
    actions: [],
    formData: {},
    file: [],
    imagePreview: '',
    idImg: Number,
    files: [],
    selectData: {
      region: [],
      category: [],
      object_type: [],
      toponyms: [],
      'toponyms-tree': [],
      morpheme: []
    },
    form: {
      name_toponym: ['', 'Название топонима *', 'input'],
      toponym_id: ['', 'Родительский топоним', 'select'],
      region_id: ['', '*Регион', 'select'],
      category_id: ['', '*Разряд', 'select'],
      object_type_id: ['', '*Тип объекта', 'select'],
      morpheme_id: ['', 'Морфема', 'select'],
      general_information: ['', '*Общие сведения', 'textarea'],
      origin_of_the_place_name: ['', '*Происхождение топонима', 'textarea'],      
      bibliography: ['', '*Cписок литературы', 'textarea'],
      author: ['', '*Автор обзора', 'input'],      
      related_toponyms: ['', 'Связанные объекты', 'select'],      
      mention_in_docs: ['', 'Упоминание в документах', 'textarea'],     
      word_formation_model: ['', 'Словообразовательная модель', 'input'],
      name_of_residents: ['', 'Наименование жителей', 'input'],
      derivatives: ['', 'Производные', 'input'],
      historical_and_cultural_information: ['', 'Историко-культурная информация', 'textarea'],      
      link_to_yandex_maps: ['', 'Ссылка на Яндекс.Карты', 'input'],      
      active: ['', 'Активен', 'checkbox'],
      moderated: ['', 'Принять', 'checkbox'],
      files: [[], 'Загрузить изображение', 'input', '', 'file'],
      priority: [100, 'Приоритет', 'select'],
    }
  },
  actions: {
    UPDATE_TOPONYM(ctx, payload) {
      this.payload = payload
      return axios({
        method: payload.method,
        url: payload.url,
        data: ctx.state.formData
      })
          .then((response) => {
            ctx.commit(this.payload.commitName, {
              responseData: response.data,
              id: this.payload.id,
              idImage: this.payload.idImage
            })
            this.payload.notify({              
              message: 'Изменения сохранены',
              duration: 3000,
            })
            this.payload.modal.hide(this.payload.modalName)
          })
          .catch((e) => {
            if (!e.response || e.response.status === 500) {
              this.payload.notify({
                type: "error",
                message: 'Ошибка сервера, попробуйте ещё раз'                
              })
            }

            if (e.response.status === 422) {
              this.payload.notify({
                type: "warning",
                message: 'Проверьте корректность данных',              
              })
            
            }
            const arrayError = e.response.data.errors
            ctx.commit('addErrors', arrayError)
          })
    },
  },

  mutations: {
    //Запись в стейт основного объекта данных
    setData(state, data) {
      state.data = data
    },
    //Сохранение тапонима с заменой объекта для пререзагрузки таблицы
    setObjectData(state, {responseData}) {
      for (let i = 0; i < state.data.length; i++) {
        for (let key in state.data[i]) {
          if (state.data[i]['id'] === state.orderData.id) {
            Object.assign(state.data[i], responseData)
            // state.data[i] = Object.assign({}, obj.resDate)
            return
          }
        }
      }
    },
    addErrors(state, arrayError) {
      state.errors = arrayError
      for (let field in arrayError) {
        if (field.substr(0, 5) === 'files') {
          state.errors['images'] = [arrayError[field][0]]
        }
        if (state.form[field][0] === '') {
          state.errors[field] = [arrayError[field][0]]
          state.form[field][0] = ''
        }
      }
    },
    // Добавление топонима в объект orderData
    SET_ORDER_DATA(state, orderData) {
      state.orderData = orderData
    },
    SET_ACTIONS(state, params) {
      state.actions = params
    },
    // Добавление данных и картинки в объект form
    setNameForm(state) {

      for (let key in state.form) {
        if (key === 'files') {
          for (let i = 0; i < 3; i++) {
            if (state.orderData[key][i] && state.orderData[key][i].images !== process.env.VUE_APP_API_URL + '/img/clip.svg') {
              state.orderData[key][i]['zoom'] = true
              state.orderData[key][i]['close'] = true
              state.orderData[key][i]['imgName'] = state.orderData[key][i].images.split('/').pop()
            } else if (state.orderData[key].length < 3) {
              for (let i = 0; i < 3; i++) {
                if (!state.orderData[key][i]) {
                  state.orderData[key].push({
                    id: 1000000000000 + i,
                    images: process.env.VUE_APP_API_URL + '/img/clip.svg',
                  })
                }
              }
            }
          }
        }
        state.form[key][0] = state.orderData[key]
      }
    },
    // Удаление картинки из объекта form
    deleteImages(state, {idImage}) {
      for (let key in state.form) {
        if (key === 'files') {
          for (let i = 0; i < 3; i++) {
            if (state.form.files[0][i].id === idImage) {
              state.orderData[key][i].images = process.env.VUE_APP_API_URL + '/img/clip.svg'
              state.orderData[key][i].zoom = false
              state.orderData[key][i].close = false
            }
          }
        }
      }
    },
    // Замена картинки в объекте form
    UPDATE_IMG(state, image) {
      for (let i in state.form.files[0]) {
        if (state.form.files[0][i].id === state.idImg) {
          state.form.files[0][i].images = image
          state.form.files[0][i].zoom = true
          state.form.files[0][i].close = false
        }
      }
      state.imagePreview = image
    },
    setSelectData(state) {
      axios.get('/category')
          .then(res => {
            for (let key in res.data) {
              for (let i in res.data[key]) {
                if (key === key) {
                  state.selectData[key][i] = res.data[key][i]
                }
              }
            }
          })
    },
    addDelImageFormData(state) {
      state.formData = new FormData()
      state.formData.append('_method', 'put')
      state.formData.append('deleteImage', state.actions.order)
    },
    ADD_FORM_DATA(state) {
      state.formData = new FormData()
      if (state.actions._method === 'put') state.formData.append('_method', 'put')
      for (let field in state.form) {
        if (field !== 'files') {
          if (Array.isArray(state.form[field][0])) {
            if (field == 'toponym_id' ) {

              const val = state.form[field][0].length
                ? state.form[field][0][state.form[field][0].length -1 ]
                : ''

              state.formData.append(field, val)
            } else {
              for(let i=0; i<state.form[field][0].length; i++) {
                state.formData.append(field + '[' + i + ']', state.form[field][0][i])
              }
            }
          } else {
            if (state.form[field][0] === null) state.form[field][0] = ''
            state.formData.append(field, state.form[field][0])
          }
        }
      }
    },
    APPEND_FORM_DATA(state) {
      for (let i in state.file) {
        state.formData.append('files[' + i + ']', state.file[i], state.form.files[0][i]['imgName'])
      }
    },
    UPDATE_MESSAGE(state, message) {    
      for (let key in state.form) {
        if (key === message.name) {
          state.form[key][0] = message.val
        }
      }
    },
    ADD_FILE_IMAGE(state, {file, id, index}) {
      state.idImg = id
      let ext = file[0].name.split('.').pop()
      if (ext === 'jpg' || ext === 'jpeg') {
        state.errors = {}
      } else {
        state.errors = {images: ['Недопустимый формат. Файл должен быть в формате jpg']}
        return
      }
      if (file[0].size > 1 * 1024 * 1024) {
        state.errors = {images: ['Превышен размер файла. Файл должен быть не более 1мг']}
        return
      }
      state.file[index] = file[0]
    },
    CLEAR_ERROR(state, name) {
      state.errors[name] = ''
    },
    delToponym(state, toponym) {
      for (let i in state.data) {
        if (toponym.id === state.data[i].id) {
          state.data.splice(i, 1)
        }
      }
    },
    ADD_NEW_TOPONYM(state) {
      state.file = []
      for (let key in state.form) {
        if (key === 'files') {
          state.form['files'][0] = []
        } else {
          state.form[key][0] = ''
        }
      }
      let newObj = {}
      for (let key in state.form) {
        newObj[key] = state.form[key][0]
      }
      state.data.unshift(newObj)
    },
    BEFORE_CLOSE(state) {
      state.data.shift()
      state.errors = {}
    }
  },

  getters: {
    getData(state) {
      return state.data
    },
    getOrderData(state) {
      return state.orderData
    },
    getFormToponym(state) {
      return state.form
    },
    getSelectData(state) {
      return state.selectData
    },
    getErrors(state) {
      return state.errors
    },
    getFile(state) {
      return state.file
    },
    getImagePreview(state) {
      return state.imagePreview
    },
    getActions(state) {
      return state.actions
    }
  }
}