export default {
  state: {
    isAuthenticated: true
  },
  mutations: {
    updateAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated
    }
  },
  getters: {
    getIsAuthenticated(state) {
      return state.isAuthenticated
    }
  }
}