import Vue from 'vue'
import Vuex from 'vuex'
import IsProcess from './isProcess.module'
import IsAuth from './isAuth.module'
import Form from './formToponym'
import Categories from './addCategories'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isShow: 'false'
  },
  mutations: {
    updateIsShow(state) {
      state.isShow = !state.isShow
    }
  },
  actions: {
  },
  getters: {
    isShow(state) {
      return state.isShow
    }
  },
  modules: {
    IsProcess,
    IsAuth,
    Form,
    Categories
  }
})
