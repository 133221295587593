// require('../bootstrap.js');
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {ClientTable, ServerTable} from 'vue-tables-2'
import VModal from 'vue-js-modal'

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import { VTooltip } from 'v-tooltip'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import Notifications from 'vue-notification'

Vue.use(require('vue-moment'));

Vue.directive('tooltip', VTooltip)

Vue.use(VTooltip)

const requireComponent = require.context(
  './components/base',
  true,
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  Vue.component(
    componentName,  
    componentConfig.default || componentConfig
  )
})

window.axios = require('axios');
window.axios.defaults.withCredentials = true;
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL + '/admin'

window.axios.interceptors.response.use(undefined, (error) => {
  if (error.response && error.response.status === 401 || error.response && error.response.status === 419) {

    if (window.location.pathname != 'login') {
      document.location.href = "/login";   
    }
  }
  return Promise.reject(error);
});

Vue.use(ClientTable, {}, false, 'bootstrap4')
Vue.use(ServerTable, {}, false, 'bootstrap4')
Vue.use(VModal)
// Vue.use(Notifications, { duration: -1})
import locale from 'element-ui/lib/locale/lang/ru-RU'
locale.el.pagination.pagesize = '';
Vue.use(ElementUI, {locale});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
