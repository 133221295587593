export default {
  state: {
    isLoading: true,
  },
  mutations: {
    updateIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },
  },
  getters: {
    getIsLoading(state) {
      return state.isLoading
    },
  }
}
