<template>
    <div class="form-group">
        <label v-if="label">{{label}}</label>
        <el-select                   
            :value="value" 
            @input="updateInput"
            v-bind="$attrs"
        >
            <el-option
              v-for="item in options"
              :key="item[optionValue]"
              :label="item[optionTitle]"
              :value="item[optionValue]">
            </el-option>
        </el-select>
        <div class="invalid-feedback" v-if="error">{{error}}</div>
        <span class="form-text text-muted" v-if="hint">{{hint}}</span>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    name: 'BaseTexarea',
    props: {
        label : {
            type: String,
            default: ''
        },
        hint : {
            type: String,
            default: ''
        },
        error : {
            type: String,
            default: ''
        },
        value: [Number, String],
        options: {
            type: Array,
            default: () => [],
            required: true
        },
        optionTitle: {
            type: String,
            default: 'title',
        },
        optionValue: {
            type: String,
            default: 'id',
        }
    },

    methods: {
        updateInput(evt) {
            this.$emit('input', evt)
        }
    }
}
</script>

<style scoped>
    .el-select {
        display: block
    }
</style>