import Vue from "vue"
import Router from "vue-router"
import jwtService from "./services/jwt.service"

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('./views/layout/MainLayout'),
      children: [
        {
          path: '/',
          name: 'Toponym',
          component: () => import('./views/toponyms/Index')
        },
        // {
        //   path: '/login',
        //   name: 'Login',
        //   component: () => import('./views/pages/Login')
        // },
        // {
        //   path: '/category',
        //   name: 'AddCategories',
        //   component: () => import('./views/pages/AddCategories')
        // },
        {
          path: '/articles',
          name: 'Articles',
          component: () => import('./views/pages/articles/Index')
        },
        {
          path: '/articles/create',
          name: 'ArticleCreate',
          component: () => import('./views/pages/articles/Create')
        },
        {
          path: '/articles/:id/edit',
          name: 'ArticleEdit',
          component: () => import('./views/pages/articles/Create'),
          props: true
        },
        {
          path: '/books',
          name: 'Books',
          component: () => import('./views/books/Index')
        },
        {
          path: '/events',
          name: 'Events',
          component: () => import('./views/events/Index')
        },
        {
          path: '/regions',
          name: 'Regions',
          component: () => import('./views/regions/Index')
        },
        {
          path: '/object-types',
          name: 'ObjectTypes',
          component: () => import('./views/object-types/Index')
        },
        {
          path: '/categories',
          name: 'Categories',
          component: () => import('./views/categories/Index')
        },
        {
          path: '/morphemes',
          name: 'Morphemes',
          component: () => import('./views/morphemes/Index')
        },
        {
          path: '/settings',
          name: 'Settings',
          component: () => import('./views/settings/Settings')
        },
        {
            path: '*', 
            name: '404', 
            component: () => import('@/views/pages/NotFound')
        },   
      ]
    },
    {
      path: "/login",
      component: () => import('./views/layout/LoginLayout'),
      children: [
        {
          path: "/login",
          name: "Login",
          component: () => import('./views/pages/Login')
        }
      ]
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !jwtService.getToken()) next({ name: 'Login' })
  else next()
})

export default router
