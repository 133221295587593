<template>
    <button  
        class="btn btn-primary mr-2" 
        :class="[btnClass, {'spinner spinner-white spinner-right': loading}]"
        :disabled="disabled"
        v-bind="$attrs" 
        v-on="$listeners">
        <slot/>
    </button> 
</template>

<script>
export default {
    inheritAttrs: false,
    name: 'BaseButton',
    props: {
        btnClass: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>