<template>
    <div class="form-group">
        <label v-if="label">{{label}}</label>
        <textarea 
            class="form-control"
            :class="{'is-invalid': error}"
            :value="value" 
            @input="updateInput"
            v-bind="$attrs"
            ></textarea> 
        <div class="invalid-feedback" v-if="error">{{error}}</div>
        <span class="form-text text-muted" v-if="hint">{{hint}}</span>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    name: 'BaseTexarea',
    props: {
        label : {
            type: String,
            default: ''
        },
        hint : {
            type: String,
            default: ''
        },
        error : {
            type: String,
            default: ''
        },
        value: [Number, String]
    },

    methods: {
        updateInput(evt) {
            this.$emit('input', evt.target.value)
        }
    }
}
</script>