<template>
  <div>
    <!-- <notifications group="edit" classes="message message-save"/>
    <notifications group="error" classes="message message-error"/>
    <notifications group="warning" classes="message message-warning"/> -->
    <router-view />
  </div>
</template>

<script>

  export default {
    name: 'App',
  }
</script>

<style lang="scss">
  @import '~bootstrap/dist/css/bootstrap.min.css';
  @import "assets/css/plugins.bundle.css";
  @import "assets/css/prismjs.bundle.css";
  @import "assets/css/style.bundle.css";

  @import "assets/css/layout/header/base/light.css";
  @import "assets/css/layout/header/menu/light.css";
  @import "assets/css/layout/brand/dark.css";
  @import "assets/css/layout/aside/dark.css";
  .message {
    font-size: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 20px;
    color: #fff;
    box-shadow: 0.4em 0.4em 5px rgba(122,122,122,0.1);
    cursor: pointer
  }
  .message-save {
    background: #679536 !important;
    padding: 20px 30px 20px 40px;
  }
  .message-error {
    background: red !important;
    padding: 20px 20px 20px 20px;
  }
  .message-warning {
    
    background: #ffb648 !important;
    padding: 20px 20px 20px 20px;
  }
</style>

